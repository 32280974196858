import * as React from 'react';
import { Router } from '@reach/router';

import { AppPageLayout, UserAccount } from 'src/app.consumer/components';
import { Grid, Link, Localized, PrivateRoute, Typography } from 'src/app/components';
import { AppProps, SeoObject } from 'src/app/types';
import { useAppContext, useAppFeatures } from 'src/app/hooks';

// TODO: refactor components for general usage?
import { AdminLoginContainer } from 'src/admin/components';
import { AdminType } from 'src/admin/constants';
import { AppFeature } from 'src/app/constants';

const basePath = '/account';

const NotFound = () => {
  const { isEmailVerified, isLoggedIn, isOrganisationUser } = useAppContext();
  if (isLoggedIn && isOrganisationUser) {
    return (
      <Grid item xs={12}>
        <Link color={'inherit'} href={'/admin/partner/account'}>
          <Typography>
            <Localized
              dictKey={'staticLocales:pages./account.error.organisation-account'}
            />
          </Typography>
        </Link>
      </Grid>
    );
  }
  if (isLoggedIn && !isEmailVerified) {
    return (
      <Grid item xs={12}>
        <Typography>
          <Localized dictKey={'staticLocales:pages./account.error.email-not-verified'} />
        </Typography>
      </Grid>
    );
  }
  return (
    <Grid item xs={12}>
      <Typography>
        <Localized dictKey={'staticLocales:pages./account.error.not-logged-in'} />
      </Typography>
    </Grid>
  );
};

const seo: SeoObject = {
  title: 'Your account',
};

const AccountPage = (props: AppProps) => {
  // const { location, pageContext } = props;
  const { isLoggedIn } = useAppContext();
  const { hasFeature: hasUserAccountFeature } = useAppFeatures(AppFeature.USER_ACCOUNT);
  return (
    <AppPageLayout
      // sidebar={<AppPageLinks />}
      subTitle={
        hasUserAccountFeature ? (
          <Localized dictKey={'staticLocales:pages./account.subtitle'} />
        ) : (
          ''
        )
      }
      title={<Localized dictKey={'staticLocales:pages./account.link label'} />}
      {...{ ...props, seo }}
    >
      {/* <CookieConsent /> // FIXME for all org sub pages + seo for alle subroutes if necessary */}
      <Router basepath={basePath}>
        {hasUserAccountFeature ? (
          <PrivateRoute path='/*' component={UserAccount} />
        ) : (
          <>
            {isLoggedIn ? (
              <NotFound path={'/*'} />
            ) : (
              <AdminLoginContainer // FIXME: refactor component to be able to price login for normal user
                path={'/*'}
                redirectAfterLoginTo={basePath}
                adminType={AdminType.SYSTEM} // TODO: rename to "type" and adjust i18n path logic
              />
            )}
          </>
        )}
      </Router>
    </AppPageLayout>
  );
};

export default AccountPage;
